import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AccordionCustom from 'components/accordian/AccordionCustom';
import ButtonSx from 'components/button/ButtonSx';
import { useCustomBlocker } from 'components/common/custom-hooks/CustomBlocker';
import HookTextField from 'components/common/hook-form-fields/HookTextField';
import ReactHookFormSelect from 'components/common/hook-form-fields/ReactHookFormSelect';
import StickyFooter from 'components/sticky-footer/StickyFooter';
import ImportCSV, {
  additionalFields,
} from 'pages/cabinet-details/common/ImportCsv';
import {
  useAddCabinetMutation,
  useGetCabinetByIdQuery,
  useUpdateCabinetMutation,
  useUploadImgMutation,
} from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
import { queryParamsBuilder } from 'utils/commonFunctions';
import { cabinetDetails, cabinetsOptions } from 'utils/constants';
import { useNotifyToast } from 'utils/useNotifyToast';

const AddEditCabinets = () => {
  const imageRef = React.useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const cabinetType = searchParams.get('type');
  const [selectedFile, setSelectedFile] = useState(null);

  const [filterCabinetData, setFilterCabinetData] = useState(cabinetDetails);
  const [imageSrc, setImageSrc] = useState('');
  const [expandedSections, setExpandedSections] = useState(() => {
    const initialState = {};
    cabinetDetails.forEach((section) => {
      initialState[section.title] = true;
    });
    return initialState;
  });
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false);
  const [imgFile, setImgFile] = React.useState(null);
  const [cabinetPayload, setCabinetPayload] = React.useState(null);

  const [isUploadImgDialogOpen, setIsUploadImgDialogOpen] =
    React.useState(false);

  const [
    uploadImg,
    {
      data: imgUploadData,
      isLoading: isImgUploadLoading,
      isSuccess: isImgUploadSuccess,
      isError: isImgUploadError,
      error: imgUploadError,
    },
  ] = useUploadImgMutation();

  const { data, isLoading, isFetching } = useGetCabinetByIdQuery(
    { id },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  // Dynamically creating the Yup validation schema
  const validateMinMax = (value, min, max, fieldName, ctx) => {
    if (min && value < min) {
      return ctx.createError({
        path: fieldName,
        message: `${fieldName} cannot be less than Min ${fieldName} (${min})`,
      });
    }
    if (max && value > max) {
      return ctx.createError({
        path: fieldName,
        message: `${fieldName} cannot be greater than Max ${fieldName} (${max})`,
      });
    }
    return true;
  };

  const crossFieldValidation = (field, value, ctx, parent) => {
    const {
      minWidth,
      maxWidth,
      maxHeight,
      minHeight,
      minDepth,
      maxDepth,
      maxToekickDepth,
      minToekickDepth,
      maxToekickHeight,
      minToekickHeight,
      maxToekickWidth,
      minToekickWidth,
    } = parent;
    const fieldName = field.id;

    switch (fieldName) {
      case 'width':
        // Ensure Width is between Min and Max Width
        if (minWidth && value < minWidth) {
          return ctx.createError({
            path: fieldName,
            message: `Width cannot be less than Min Width (${minWidth})`,
          });
        }
        if (maxWidth && value > maxWidth) {
          return ctx.createError({
            path: fieldName,
            message: `Width cannot be greater than Max Width (${maxWidth})`,
          });
        }
        return validateMinMax(value, minWidth, maxWidth, fieldName, ctx);

      case 'height':
        if (minHeight && value < minHeight) {
          return ctx.createError({
            path: fieldName,
            message: `Height cannot be less than Min Height (${minHeight})`,
          });
        }
        if (maxHeight && value > maxHeight) {
          return ctx.createError({
            path: fieldName,
            message: `Height cannot be greater than Max Height (${maxHeight})`,
          });
        }
        return validateMinMax(value, minHeight, maxHeight, fieldName, ctx);
      case 'depth':
        if (minDepth && value < minDepth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be less than Min Depth (${minDepth})`,
          });
        }
        if (maxDepth && value > maxDepth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be greater than Max Depth (${maxDepth})`,
          });
        }
        return validateMinMax(value, minDepth, maxDepth, fieldName, ctx);
      case 'toekickDepth':
        if (minToekickDepth && value < minToekickDepth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be less than Min Depth (${minToekickDepth})`,
          });
        }
        if (maxToekickDepth && value > maxToekickDepth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be greater than Max Depth (${maxToekickDepth})`,
          });
        }
        return validateMinMax(
          value,
          minToekickDepth,
          maxToekickDepth,
          fieldName,
          ctx
        );
      case 'toekickHeight':
        if (minToekickHeight && value < minToekickHeight) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be less than Min Depth (${minToekickHeight})`,
          });
        }
        if (maxToekickHeight && value > maxToekickHeight) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be greater than Max Depth (${maxToekickHeight})`,
          });
        }
        return validateMinMax(
          value,
          minToekickHeight,
          maxToekickHeight,
          fieldName,
          ctx
        );
      case 'toekickWidth':
        if (minToekickWidth && value < minToekickWidth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be less than Min Depth (${minToekickWidth})`,
          });
        }
        if (maxToekickWidth && value > maxToekickWidth) {
          return ctx.createError({
            path: fieldName,
            message: `Depth cannot be greater than Max Depth (${maxToekickWidth})`,
          });
        }
        return validateMinMax(
          value,
          minToekickWidth,
          maxToekickWidth,
          fieldName,
          ctx
        );

      default:
        return true;
    }
  };

  const schema = Yup.object().shape(
    [
      ...(cabinetType === 'extras'
        ? cabinetDetails.slice(0, 2)
        : cabinetDetails),
    ].reduce((acc, section) => {
      section?.fields?.forEach((field) => {
        const fieldValidation =
          field.type === 'number'
            ? Yup.number()
                .nullable()
                .transform((curr, originalValue) =>
                  originalValue === '' ? null : curr
                )
                .typeError(`${field.label} must be a number`)
            : Yup.string();

        if (section.title.startsWith('Additional Parameter')) {
          acc[field.id] = Yup.string();
        } else {
          acc[field.id] = fieldValidation.required(
            `${field.label} is required`
          );
        }

        if (
          [
            'width',
            'height',
            'depth',
            'toekick',
            'minWidth',
            'maxWidth',
            'minHeight',
            'maxHeight',
            'minDepth',
            'maxDepth',
            'toekickHeight',
            'toekickDepth',
            'maxToekickDepth',
            'minToekickDepth',
            'maxToekickHeight',
            'minToekickHeight',
          ].includes(field.id)
        ) {
          acc[field.id] = acc[field.id].test(
            'valid-dimension',
            `${field.label} must be between Min and Max ${field.label}`,
            function (value) {
              return crossFieldValidation(field, value, this, this.parent);
            }
          );
        }
      });
      return acc;
    }, {})
  );

  const defaultValues = cabinetDetails.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.id] = '';
    });
    return acc;
  }, {});

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const isToeKickVisible = watch('toekick');
  const isAdjShelvesVisible = watch('adjShelves');

  useEffect(() => {
    setFilterCabinetData(() => {
      let updatedCabinetDetails =
        cabinetType === 'extras' ? cabinetDetails.slice(0, 2) : cabinetDetails;

      if (isToeKickVisible === 'No') {
        updatedCabinetDetails = updatedCabinetDetails.map((section) => ({
          ...section,
          fields: section.fields.filter((field) => {
            if (section.title !== 'Filters') {
              return !field.label.toLowerCase().includes('toekick');
            }
            return true;
          }),
        }));
      }

      if (isAdjShelvesVisible === 'No') {
        updatedCabinetDetails = updatedCabinetDetails.filter((item) => {
          return !item.title.startsWith('Additional Parameter');
        });
      }

      return updatedCabinetDetails;
    });
  }, [cabinetType, isAdjShelvesVisible, isToeKickVisible]);

  const notifyToast = useNotifyToast();
  const [
    addCabinet,
    {
      data: addData,
      isSuccess: isAddSuccess,
      isError: isAddError,
      error: addError,
      isLoading: isAddCabLoading,
    },
  ] = useAddCabinetMutation();
  const [
    updateCabinet,
    {
      data: updateData,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateCabinetMutation();

  useEffect(() => {
    if (isImgUploadSuccess && !isImgUploadLoading) {
      if (id) {
        notifyToast(
          imgUploadData?.message || 'Image updated successfully!',
          'ImageUpdateSuccess',
          'success'
        );
      } else {
        addCabinet([{ ...cabinetPayload[0], img: imgUploadData.imgLink }]);
      }
      setIsUploadImgDialogOpen(false);
    } else if (isImgUploadError) {
      const errorMessage =
        imgUploadError?.data?.message || 'Failed to add image.';
      notifyToast(errorMessage, 'cabinetAddError', 'error');
    }
  }, [
    addCabinet,
    id,
    cabinetPayload,
    imgUploadData,
    imgUploadData?.message,
    imgUploadError?.data?.message,
    isImgUploadError,
    isImgUploadLoading,
    isImgUploadSuccess,
    notifyToast,
  ]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notifyToast(
        updateData?.message || 'Cabinet updated successfully!',
        'cabinetUpdateSuccess',
        'success'
      );
      navigate(`/cabinets${queryParamsBuilder({ type: cabinetType })}`);
    } else if (isUpdateError) {
      const errorMessage =
        updateError?.data?.message || 'Failed to update cabinet.';
      notifyToast(errorMessage, 'cabinetUpdateError', 'error');
    }
  }, [
    cabinetType,
    isUpdateError,
    isUpdateSuccess,
    navigate,
    notifyToast,
    updateData?.message,
    updateError?.data?.message,
  ]);

  useEffect(() => {
    if (isAddSuccess) {
      notifyToast(
        addData?.message || 'Cabinet added successfully!',
        'cabinetAddSuccess',
        'success'
      );
      navigate(`/cabinets${queryParamsBuilder({ type: cabinetType })}`);
    } else if (isAddError) {
      const errorMessage = addError?.data?.message || 'Failed to add cabinet.';
      notifyToast(errorMessage, 'cabinetAddError', 'error');
    }
  }, [
    addData?.message,
    addError?.data?.message,
    cabinetType,
    isAddError,
    isAddSuccess,
    navigate,
    notifyToast,
  ]);

  useEffect(() => {
    if (data) {
      cabinetDetails.forEach((section) => {
        section?.fields?.forEach((field) => {
          setValue(
            field.id,
            typeof data[field.id] === 'boolean'
              ? data[field.id]
                ? 'Yes'
                : 'No'
              : data[field.id] || 'none'
          );
        });
      });

      // set the adjustment Parameters
      if (data?.adjustmentParameters) {
        data?.adjustmentParameters?.forEach((param, index) => {
          setValue(`nameP${index + 1}`, param.id || '');
          setValue(`descP${index + 1}`, param.desc || '');
          setValue(`typeP${index + 1}`, param.type || '');
          setValue(`minValueP${index + 1}`, param.minValue || '');
          setValue(`maxValueP${index + 1}`, param.maxValue || '');
          setValue(`FormulaP${index + 1}`, param.value || '');
        });
      }
    }
  }, [data, setValue]);

  const [isFormSaved, setIsFormSaved] = useState(false);

  const onSubmit = (formData) => {
    setIsFormSaved(true);
    let payload = {
      ...formData,
      type: cabinetType.toUpperCase(),
      rightEnd: 0,
      leftEnd: 0,
      hinging: 'OPEN',
    };

    // Convert "Yes" or "No" to boolean
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (typeof value === 'string') {
        const lowerCaseValue = value.toLowerCase();
        if (lowerCaseValue === 'yes') {
          payload[key] = true;
        } else if (lowerCaseValue === 'no') {
          payload[key] = false;
        } else if (lowerCaseValue === 'none') {
          payload[key] = null;
        }
      }
    });

    // Construct adjustmentParameters array
    const adjustmentParameters = [];
    for (let i = 1; i <= 6; i++) {
      const id = payload[`${additionalFields[0]}${i}`];
      const desc = payload[`${additionalFields[1]}${i}`];
      const type = payload[`${additionalFields[2]}${i}`];
      const minValue = payload[`${additionalFields[3]}${i}`];
      const maxValue = payload[`${additionalFields[4]}${i}`];
      const value = payload[`${additionalFields[5]}${i}`];

      // Check if all fields are "N/A"
      const allFieldsNA = [id, desc, type, minValue, maxValue, value].every(
        (field) => field === 'none' || field === 'null' || field === null
      );

      if (
        !allFieldsNA &&
        (id || desc || type || minValue || maxValue || value)
      ) {
        adjustmentParameters.push({
          id,
          desc,
          type,
          minValue,
          maxValue,
          value,
        });
      }

      // Remove additional parameter fields from payload
      delete payload[`${additionalFields[0]}${i}`];
      delete payload[`${additionalFields[1]}${i}`];
      delete payload[`${additionalFields[2]}${i}`];
      delete payload[`${additionalFields[3]}${i}`];
      delete payload[`${additionalFields[4]}${i}`];
      delete payload[`${additionalFields[5]}${i}`];
    }

    // Convert adjustmentParameters to JSON string or set to null

    payload.adjustmentParameters =
      adjustmentParameters.length > 0
        ? JSON.stringify(adjustmentParameters)
        : null;

    const payloadForAdditionalItem = filterCabinetData?.reduce((acc, item) => {
      acc = [...acc, ...item.fields];

      return acc;
    }, []);

    if (cabinetType === 'extras') {
      payload = [
        ...payloadForAdditionalItem,
        { id: 'img' },
        { id: 'type' },
      ].reduce((acc, { id }) => {
        if (Object.keys(payload).includes(id)) {
          acc[id] = payload[id];
        }
        return acc;
      }, {});
    }

    if (id) {
      payload.cabinetId = id;
      updateCabinet(payload);
    } else {
      if (!imgFile) {
        addCabinet([payload]);
      } else {
        setCabinetPayload([payload]);
        uploadImg({ formData: imgFile });
      }
    }
  };

  const handleAccordionChange = (title) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const [customPrompt] = useCustomBlocker({
    isDirty: Object.values(getValues())?.some((item) => item) && !isFormSaved,
    handleSaveData: () => handleSubmit(onSubmit)(),
  });

  const handleClose = () => {
    setImageSrc(null);
    setIsUploadImgDialogOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      setSelectedFile(file);

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result;
        setImageSrc(fileContent);
      };

      reader.onerror = (error) => {
        console.error('Error reading the image file', error);
      };

      reader.readAsDataURL(file);
    } else {
      alert('Please upload a valid image file (PNG, JPEG, JPG).');
    }
  };

  const handleUploadImg = () => {
    if (!id) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setValue('img', imageSrc);
      setImgFile(formData);
      setIsUploadImgDialogOpen(false);
      setImageSrc(null);

      return;
    }
    if (!selectedFile) {
      notifyToast('No Image selected for upload!', 'NoImgFile', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    uploadImg({ formData, id });

    setIsUploadImgDialogOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3} sx={{ p: { xs: 2, md: 0 } }}>
          {id ? (
            <>
              {!data ? (
                <Skeleton variant="text" height={30} width={200} />
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                      color: '#333',
                      borderBottom: '2px solid #ddd',
                      paddingBottom: 1,
                    }}
                  >
                    {data?.code}: {data?.shortName}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Stack
              gap={2}
              sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 2 }}
              alignItems={'center'}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                width={'100%'}
                gap={2}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    width: 'fit-content',
                    whiteSpace: 'nowrap',
                    fontSize: { xs: '18px', md: '22px' },
                  }}
                >
                  Selected Cabinet Type:
                </Typography>
                <FormControl
                  sx={{
                    minWidth: '30%',
                    width: { xs: '100%', md: '200px' },
                  }}
                >
                  <InputLabel id="cabinet-type-select-label">
                    Cabinet Type
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: 0 }}
                    labelId="cabinet-type-select-label"
                    id="cabinet-type-select"
                    value={cabinetType || 'base'}
                    label="Cabinet Type"
                    onChange={(e) => {
                      reset();
                      setSearchParams({ type: e.target.value });
                    }}
                  >
                    {cabinetsOptions?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                gap={2}
                sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                width={'100%'}
                justifyContent={'end'}
              >
                <ImportCSV
                  buttonText={
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Import Single Cabinet
                    </Typography>
                  }
                  isUploadDialogOpen={isUploadDialogOpen}
                  handleClose={() => setIsUploadDialogOpen(false)}
                  setIsUploadDialogOpen={setIsUploadDialogOpen}
                  cabinetType={cabinetType}
                  onImport={(submitFunction, csvData) => {
                    reset();
                    setImgFile(null);

                    csvData?.forEach((item) => {
                      Object.keys(item).forEach((key) => {
                        setValue(
                          key,
                          item[key] === 'null' || item[key] === '\r'
                            ? ''
                            : item[key]
                        );
                      });
                    });
                    notifyToast(
                      'Cabinet Details Imported Successfully',
                      'cabinetImportCsvFile',
                      'success'
                    );
                  }}
                />
              </Stack>
            </Stack>
          )}
          <Box
            sx={{
              'display': 'flex',
              'flexDirection': 'column',
              'alignItems': 'center',
              'justifyContent': 'center',
              'padding': 2,
              'border': '2px dashed #ccc',
              'borderRadius': '8px',
              'width': '100%',
              'position': 'relative',
              '&:hover .upload-button': {
                visibility: 'visible',
                opacity: 1,
              },
            }}
          >
            {isImgUploadLoading || isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="20vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  component="img"
                  src={
                    getValues('img') ||
                    (!data?.img ? placeHolderImage : data?.img) ||
                    placeHolderImage
                  }
                  alt="Cabinet Image"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '250px',
                    objectFit: 'contain',
                    marginBottom: 2,
                    borderRadius: 1,
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // Prevent looping on error
                  }}
                />

                <Button
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  className="upload-button"
                  onClick={() => {
                    if (!imageSrc) {
                      setImageSrc(null);
                    }
                    setIsUploadImgDialogOpen(true);
                  }}
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 15,
                    visibility: 'hidden',
                    opacity: 0,
                    transition: 'visibility 0s, opacity 0.3s ease',
                  }}
                >
                  {data?.img || getValues('img') ? 'Update' : 'Upload'} Image
                </Button>

                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                />
              </>
            )}
          </Box>
          {filterCabinetData.map((section) => (
            <AccordionCustom
              key={section.title}
              title={
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                  }}
                >
                  {section.title}
                </Typography>
              }
              expanded={expandedSections[section.title]}
              handleChange={() => handleAccordionChange(section.title)}
              sx={{
                '& .MuiAccordionSummary-root': {
                  background: '#f0f0f0',
                },
                '&::before': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {isLoading || isFetching ? (
                <Grid container spacing={2}>
                  {section.fields.map((field) => (
                    <Grid item xs={12} md={6} key={field.id}>
                      <Skeleton
                        sx={{ mb: 1 }}
                        variant="text"
                        height={20}
                        width={60}
                      />
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {section.fields.map((field) => {
                    return (
                      <Grid item xs={12} md={4} key={field.id}>
                        {field.type === 'select' ? (
                          <ReactHookFormSelect
                            control={control}
                            label={field.label}
                            name={field.id}
                            defaultValue={''}
                            error={!!errors[field.id]}
                            helperText={errors[field.id]?.message}
                          >
                            {field?.options?.map((item) => (
                              <MenuItem value={item} key={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </ReactHookFormSelect>
                        ) : (
                          <HookTextField
                            isNotRequired={
                              section.title.startsWith('Additional Parameter')
                                ? true
                                : false
                            }
                            control={control}
                            label={field.label}
                            name={field.id}
                            placeholder={field.label}
                            type={field?.type || 'text'}
                            error={!!errors[field.id]}
                            helperText={errors[field.id]?.message}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </AccordionCustom>
          ))}
          <Divider
            variant="fullWidth"
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            }}
          />

          <StickyFooter>
            <Stack
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
              gap={'15px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <ButtonSx
                sx={{
                  fontWeight: 700,
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
                buttonText="Cancel"
                variant={'light-bg'}
                onButtonPress={() => {
                  navigate(
                    `/cabinets${queryParamsBuilder({ type: cabinetType })}`
                  );
                }}
                disabled={isLoading || isFetching}
              />
              <ButtonSx
                type="submit"
                sx={{
                  fontWeight: 700,
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
                isLoading={isAddCabLoading}
                buttonText={`${id ? 'Update' : 'Save'} Details`}
                variant={'light-bg'}
                disabled={isLoading || isFetching}
              />
            </Stack>
          </StickyFooter>
        </Stack>
      </form>

      <Dialog
        open={isUploadImgDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            Upload Image
          </Stack>
        </DialogTitle>
        <DialogContent>
          <>
            {imageSrc ? (
              <Box
                component="img"
                src={imageSrc}
                alt="creamCabinet"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '250px',
                  objectFit: 'contain',
                  marginBottom: 2,
                  borderRadius: 1,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                }}
              />
            ) : (
              <Box
                component="label"
                htmlFor="file-upload"
                sx={{
                  'display': 'flex',
                  'flexDirection': 'column',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'border': '2px dashed #1976d2',
                  'padding': '20px',
                  'textAlign': 'center',
                  'cursor': 'pointer',
                  'width': '100%',
                  'maxWidth': '500px',
                  'minHeight': '200px',
                  'margin': 'auto',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <CloudUploadIcon sx={{ mb: 2, color: '#1976d2' }} />
                <Typography sx={{ color: '#1976d2', fontSize: 16 }}>
                  Upload Image
                </Typography>
                <input
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  id="file-upload"
                  onChange={handleFileChange}
                  ref={imageRef}
                  style={{ display: 'none' }}
                />
              </Box>
            )}
          </>
        </DialogContent>
        <DialogActions>
          {[
            {
              title: 'Cancel',
              onClick: handleClose,
            },
            {
              title: data?.img || getValues('img') ? 'Update' : 'Save',
              onClick: handleUploadImg,
              props: {
                disabled: !imageSrc,
              },
            },
          ].map((btn) => (
            <Button
              key={btn.title}
              onClick={btn?.onClick}
              color="primary"
              sx={{
                fontSize: '18px',
              }}
              {...btn?.props}
            >
              {btn?.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>

      {customPrompt}
    </>
  );
};

export default AddEditCabinets;
