import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import RestoreIcon from '@mui/icons-material/Restore';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import FileSaver from 'file-saver';
import debounce from 'lodash.debounce';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AlertPopup from 'components/alert-popup/AlertPopup';
import ButtonSx from 'components/button/ButtonSx';
import DynamicForm from 'components/common/DynamicForm';
import ProjectPopup from 'components/project-popup/ProjectPopup';
import useAuth from 'context/AuthContext';
import { datagridSx } from 'pages/orders/Orders';
import { useGetCustomerQuery } from 'store/apis/customer';
import {
  useAddUpdateMaterialsMutation,
  useDeleteMaterialMutation,
  useGetSingleMaterialQuery,
  useRestoreMaterialMutation,
  useUploadMaterialImgMutation,
} from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
import { capitalizeAndSplitCamelCase } from 'utils/commonFunctions';
import {
  expectedHeaders,
  headersConversion,
  materialsOptions,
  numericFields,
  removedCols,
  types,
} from 'utils/constants';
import { useNotifyToast } from 'utils/useNotifyToast';

import styles from '../cabinet-details/cabinetDetails.module.css';

const percentageFields = ['waste'];
const transformData = (data) => {
  return Object.entries(data || {})
    .filter(([key]) => !removedCols.includes(key))
    ?.map((item) => ({
      id: item[0],
      name: `${capitalizeAndSplitCamelCase(item[0])} *`,
      type: types.dropdown.includes(item[0])
        ? 'dropdown'
        : types.text.includes(item[0])
        ? 'text'
        : 'number',
      value: types.dropdown.includes(item[0]) ? 'No' : '',
      ...(types.dropdown.includes(item[0]) && {
        options: ['Yes', 'No'],
      }),
    }));
};

export default function BulkEditing() {
  const auth = useAuth();

  const [openImgDialog, setOpenImgDialog] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');
  const imageRef = React.useRef(null);
  const [imageSrc, setImageSrc] = React.useState('');

  const [rows, setRows] = React.useState([]);
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [confirmValue, setConfirmValue] = React.useState('');
  const [selectedMaterialId, setSelectedMaterialId] = React.useState('');
  const [addEditPopup, setAddEditPopup] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false);

  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );
  const navigate = useNavigate();

  const [selectedMaterial, setSelectedMaterial] = React.useState(
    materialsOptions?.[0]?.id
  );

  const [searchText, setSearchText] = React.useState('');

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = React.useState([
    { field: 'index', sort: 'asc' },
  ]);

  const [
    uploadMatImg,
    {
      data: imgUploadData,
      isLoading: isImgUploadLoading,
      isSuccess: isImgUploadSuccess,
      isError: isImgUploadError,
      error: imgUploadError,
    },
  ] = useUploadMaterialImgMutation();

  const {
    data: materialData,
    isLoading: isMaterialLoading,
    isFetching: isMaterialFetching,
  } = useGetSingleMaterialQuery({
    ...paginationModel,
    page: paginationModel.page + 1,
    type: selectedMaterial,
    search: searchText.trim(),
    orderBy: sortModel?.[0]?.sort || 'asc',
  });

  const [addUpdateMaterials, { data, isLoading, isSuccess, isError, error }] =
    useAddUpdateMaterialsMutation();

  const [
    restoreMat,
    {
      isLoading: isRestoreMatLoading,
      isSuccess: isRestoreMatSuccess,
      isError: isRestoreMatError,
      error: restoreMatError,
    },
  ] = useRestoreMaterialMutation();

  const [
    deleteMaterial,
    {
      isLoading: isMaterialDeleting,
      isSuccess: isMaterialDeleteSuccess,
      isError: isMaterialDeleteError,
      error: materialDeleteError,
    },
  ] = useDeleteMaterialMutation();

  const handleDeleteMaterial = React.useCallback(() => {
    setConfirmValue('');
    deleteMaterial(selectedMaterialId);
  }, [deleteMaterial, selectedMaterialId]);

  const handleChange = React.useCallback((event) => {
    setSelectedMaterial(event);
  }, []);
  const notifyToast = useNotifyToast();

  React.useEffect(() => {
    if (!isMaterialDeleting && isMaterialDeleteSuccess) {
      setIsAlertOpen(false);
      notifyToast(
        'Material deleted successfully!',
        'materialDeleteSuccess',
        'success'
      );
    } else if (isImgUploadSuccess && !isImgUploadLoading) {
      setOpenImgDialog(false);
      setImageSrc(null);

      notifyToast(
        imgUploadData?.message || 'Image updated successfully!',
        'ImageUpdateSuccess',
        'success'
      );
    } else if (isImgUploadError) {
      const errorMessage =
        imgUploadError?.data?.message || 'Failed to add image.';
      notifyToast(errorMessage, 'matAddError', 'error');
    } else if (isMaterialDeleteError) {
      notifyToast(
        materialDeleteError?.data?.errorMessage,
        'updateError',
        'error'
      );
    }
  }, [
    imgUploadData?.message,
    imgUploadError?.data?.message,
    isImgUploadError,
    isImgUploadLoading,
    isImgUploadSuccess,
    isMaterialDeleteError,
    isMaterialDeleteSuccess,
    isMaterialDeleting,
    materialDeleteError?.data?.errorMessage,
    notifyToast,
  ]);
  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      setIsAlertOpen(false);
      notifyToast(data?.msg, 'materialUpdateSuccess', 'success');
    } else if (!isRestoreMatLoading && isRestoreMatSuccess) {
      notifyToast(
        'Material restored successfully',
        'materialRestore',
        'success'
      );
    } else if (isRestoreMatError && restoreMatError) {
      notifyToast(
        restoreMatError?.data?.errorMessage || 'Something went wrong',
        'materialRestore',
        'error'
      );
    } else if (isError) {
      notifyToast(error?.data?.errorMessage, 'updateError', 'error');
    }
  }, [
    data?.msg,
    error?.data?.errorMessage,
    isError,
    isLoading,
    isRestoreMatError,
    isRestoreMatLoading,
    isRestoreMatSuccess,
    isSuccess,
    notifyToast,
    restoreMatError,
  ]);

  const materialEntries = Object.entries(materialData?.data?.[0] || []).filter(
    ([key]) => !removedCols.includes(key)
  );

  // Fallback to headersConversion if materialEntries is empty
  const entriesToMap =
    materialEntries.length > 0
      ? materialEntries
      : Object.entries(headersConversion);

  const columns = React.useMemo(
    () =>
      [
        {
          field: 'img',
          headerName: 'Image',
          width: 150,
          align: 'center',
          headerAlign: 'center',
          renderCell: ({ id, row }) => {
            const imgUrl = row?.img;
            return (
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                alignItems="center"
                height={'100%'}
              >
                <div className={styles.uploadContainer}>
                  {imgUrl ? (
                    <img
                      src={imgUrl}
                      alt={`${row?.name}-img`}
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                      }}
                      onClick={() => {
                        if (imgUrl) {
                          setImageSrc(null);
                        }
                        setSelectedMaterialId(id);
                        handleImageClick(imgUrl);
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '5px',
                        backgroundImage: `url(${placeHolderImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                      className={styles.uploadWrapper}
                    >
                      <IconButton
                        disabled={row?.isDeleted}
                        onClick={() => {
                          setImageUrl(null);

                          setSelectedMaterialId(id);
                          setOpenImgDialog(true);
                        }}
                        className={styles.uploadIcon}
                      >
                        <UploadIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Stack>
            );
          },
        },
        ...entriesToMap.map(([key]) => ({
          field: key,
          headerName: `${headersConversion[key]} ${
            percentageFields?.includes(key) ? '%' : ''
          }`,
          sortable: key === 'index',
          sortComparator:
            key === 'index' ? (v1, v2) => Number(v1) - Number(v2) : undefined,
          minWidth: 255,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                alignItems="center"
                height={'100%'}
              >
                <Tooltip title={`${params?.row?.[key]}`} placement="top" arrow>
                  <Typography
                    noWrap
                    sx={{
                      maxWidth: '250px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {typeof params?.row?.[key] === 'boolean'
                      ? `${params?.row?.[key] ? 'Yes' : 'No'}`
                      : `${params?.row?.[key]} ${
                          percentageFields?.includes(key) ? '%' : ''
                        }` ?? 'N/A'}
                  </Typography>
                </Tooltip>
              </Stack>
            );
          },
        })),
        ...[
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
              const actions = [];
              if (row?.isDeleted) {
                actions.push(
                  <Tooltip title="Restore material">
                    <IconButton
                      onClick={() => {
                        restoreMat(id);
                      }}
                    >
                      <RestoreIcon
                        sx={{ color: (theme) => theme.palette.info.light }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              } else {
                actions.push(
                  <GridActionsCellItem
                    key={id}
                    icon={
                      <BorderColorIcon
                        sx={{ color: (theme) => theme.palette.success.light }}
                      />
                    }
                    label="Edit"
                    onClick={() => {
                      const fields = Object.entries(row || {})
                        .filter(([key]) => !removedCols.includes(key))
                        ?.map((item) => ({
                          id: item[0],
                          name: capitalizeAndSplitCamelCase(item[0]),
                          type: types.dropdown.includes(item[0])
                            ? 'dropdown'
                            : types.text.includes(item[0])
                            ? 'text'
                            : 'number',
                          value:
                            typeof item[1] === 'boolean'
                              ? item[1]
                                ? 'Yes'
                                : 'No'
                              : item[1],
                          ...(types.dropdown.includes(item[0]) && {
                            options: ['Yes', 'No'],
                          }),
                        }));
                      setSelectedRow({ id, fields });
                      setAddEditPopup(true);
                    }}
                  />
                );

                if (row?.index !== 1) {
                  actions.push(
                    <GridActionsCellItem
                      key={id}
                      icon={
                        <DeleteIcon
                          sx={{ color: (theme) => theme.palette.error.dark }}
                        />
                      }
                      label="Delete"
                      onClick={() => {
                        setSelectedMaterialId(id);
                        setIsAlertOpen(true);
                      }}
                    />
                  );
                }
              }

              return actions;
            },
          },
        ],
      ] || [],
    [entriesToMap, restoreMat]
  );

  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: selectedRow?.fields?.reduce((acc, item) => {
      acc[item.id] = item.value || '';
      return acc;
    }, {}),
    mode: 'onChange',
  });

  const handleSaveChanges = () => {
    const newAddedData = [
      {
        data: [...Object.values(getValues()), selectedMaterial],
      },
    ];
    const updatedData = [
      {
        id: selectedRow?.id,
        data: [...Object.values(getValues()), selectedMaterial],
      },
    ];

    if (selectedRow?.id) {
      addUpdateMaterials({ updatedData });
    } else {
      addUpdateMaterials({ newAddedData });
    }

    setSelectedRow({});
    reset();
    setAddEditPopup(false);
  };

  React.useEffect(() => {
    if (materialData?.data) {
      setRows(materialData?.data || []);
    }
  }, [materialData, selectedMaterial]);

  React.useEffect(() => {
    if (user && user?.role !== 'ADMIN') {
      navigate('/');
    }
  }, [navigate, user]);

  const handleClose = () => {
    setIsUploadDialogOpen(false);
  };

  const csvRef = React.useRef(null);

  const getRowClassName = (params) => {
    return params.row.isDeleted ? styles.disabledRow : '';
  };

  const [csvData, setCsvData] = React.useState([]);
  const [fileName, setFileName] = React.useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      notifyToast('No file selected', 'invalidCSVFile', 'error');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;

      if (!text.trim()) {
        notifyToast(
          'The selected CSV file is empty. Please upload a valid file.',
          'emptyCSVFile',
          'error'
        );

        return;
      }

      const rows = text
        .split(/\r?\n/)
        .filter((row) => row.trim() !== '')
        .map((row) =>
          row
            .split(',')
            .map((value) => value.replace(/%/g, '').trim())
            .join(',')
        );

      // Ensure the CSV has at least a header and one row of data
      if (rows.length < 2) {
        notifyToast(
          'The CSV file must contain a header row and at least one data row.',
          'invalidFileStructure',
          'error'
        );

        return;
      }

      const headerRow = rows[0]
        .split(',')
        .map((header) => header.trim())
        .map((header) => header.replace(/^"|"$/g, ''))
        .map((header) => header.replace(/""/g, '"'));

      // Expected headers

      if (
        headerRow.length !== expectedHeaders.length ||
        !expectedHeaders.every((header, idx) => header === headerRow[idx])
      ) {
        notifyToast(
          'The headers in the CSV file are incorrect. Please use the sample file as a reference.',
          'invalidHeaders',
          'error'
        );

        return;
      }

      const data = rows.slice(1).map((row, rowIndex) => {
        const values = row
          .split(',')
          .map((value) => value.replace(/%/g, '').trim());

        // Validate column values
        if (values.length !== expectedHeaders.length) {
          notifyToast(
            'File has incorrect number of columns. Please refer the sample file.',
            'invalidColumns',
            'error'
          );

          return null;
        }

        const rowObject = expectedHeaders.reduce((acc, header, index) => {
          acc[header] = values[index];
          return acc;
        }, {});

        // Column-specific validations
        const errors = [];

        if (!rowObject['Name']) {
          notifyToast(
            'Material Name cannot be empty.',
            'invalidRowType',
            'error'
          );
          return null;
        }
        if (isNaN(rowObject['Index']) || rowObject['index'] === '') {
          notifyToast('Index cannot be empty', 'invalidRowType', 'error');
          return null;
        }

        if (isNaN(rowObject['MatID']) || rowObject['MatID'] === '') {
          notifyToast('Invalid Material Id', 'invalidRowType', 'error');
          return null;
        }

        if (!['Yes', 'No'].includes(rowObject['Grain'])) {
          notifyToast('Grain must be Yes or No', 'invalidRowType', 'error');
          return null;
        }

        if (!['Yes', 'No'].includes(rowObject['Out Source'])) {
          notifyToast(
            'OutSource must be Yes or No.',
            'invalidRowType',
            'error'
          );
          return null;
        }

        // Numeric validations

        numericFields.forEach((field) => {
          if (isNaN(rowObject[field]) || rowObject[field] === '') {
            errors.push(`${field}`);
          }
        });

        // If there are any errors, alert and exclude the row
        if (errors.length > 0) {
          notifyToast(
            `Some Row has errors. Refer: ${errors.join(', ')}`,
            'invalidRowType',
            'error'
          );

          return null;
        }

        return rowObject;
      });

      if (data?.some((item) => item === null)) {
        return;
      }

      const validData = data.filter((item) => item !== null);

      const hasDuplicateData = validData?.some((item) => {
        return materialData?.data?.some((subItem) => {
          return subItem?.name === item?.['Name'];
        });
      });
      if (hasDuplicateData) {
        notifyToast(
          'Duplicate materials are not allowed',
          'duplicateMaterials',
          'error'
        );

        return;
      }

      if (!validData?.length || validData?.length !== data?.length) {
        return;
      }

      setFileName(file.name);

      let newAddedData = [];

      newAddedData = validData.map((item, index) => ({
        data: [...Object.values(item), selectedMaterial],
      }));

      setCsvData(newAddedData);
    };

    csvRef.current.value = '';

    reader.readAsText(file);
  };

  const handleImportCsvFile = () => {
    addUpdateMaterials({ newAddedData: csvData });
  };

  const handleImageClick = (imgUrl) => {
    setImageUrl(imgUrl);
    setOpenImgDialog(true);
  };

  const handleCloseImgDialog = () => {
    setOpenImgDialog(false);
  };

  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleImgFileChange = (event) => {
    const file = event.target.files[0];

    if (
      file &&
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg')
    ) {
      setSelectedFile(file);

      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result;
        setImageSrc(fileContent);
      };

      reader.onerror = (error) => {
        console.error('Error reading the image file', error);
      };

      reader.readAsDataURL(file);
    } else {
      alert('Please upload a valid image file (PNG, JPEG, JPG).');
    }
  };

  const handleUploadImg = () => {
    if (!selectedFile) {
      notifyToast('No Image selected for upload!', 'NoImgFile', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    uploadMatImg({ formData, matId: selectedMaterialId });
  };

  return (
    <Box sx={{ height: '100%', mx: 2 }}>
      <Grid container mb={2.5} width="100%">
        <Grid item xs={12}>
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              alignItems: { sm: 'center' },
            }}
            justifyContent={'space-between'}
            gap={2}
          >
            <Stack
              direction="row"
              sx={{ gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}
              width={'100%'}
            >
              <Typography
                flexWrap={'wrap'}
                sx={{
                  fontSize: pxToRem(30),
                  fontWeight: 700,
                  mt: { xs: 2, sm: 0 },
                  mr: 1,
                }}
              >
                Materials
              </Typography>
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { sm: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Selected Material
                </InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMaterial}
                  label="Selected Material"
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                >
                  {materialsOptions?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                onChange={debounce((e) => {
                  setSearchText(e.target.value);
                }, 300)}
                placeholder="Search"
                sx={{
                  width: '100%',
                  input: {
                    'borderRadius': '2px',
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
              width={'100%'}
              justifyContent={'end'}
            >
              <ButtonSx
                startIcon={<AddIcon />}
                buttonText={
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Add Material
                  </Typography>
                }
                onButtonPress={() => {
                  setSelectedRow({
                    fields: transformData(materialData?.data?.[0]),
                  });

                  setAddEditPopup(true);
                }}
                isLoading={false}
                sx={{
                  borderRadius: '5px',
                  height: 50,
                }}
              />
              <ButtonSx
                buttonText={
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Import Materials
                  </Typography>
                }
                onButtonPress={() => {
                  setCsvData([]);
                  setFileName('');
                  setIsUploadDialogOpen(true);
                }}
                isLoading={false}
                sx={{
                  borderRadius: '5px',
                  height: 50,
                }}
              />
            </Stack>
            <Box sx={{ display: 'none' }}>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={csvRef}
              />
            </Box>{' '}
          </Stack>
        </Grid>
      </Grid>
      <Paper
        elevation={0}
        sx={{
          'height': 'auto',

          'width': '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          'mb': 10,
          'zIndex': 0,
        }}
      >
        <DataGrid
          sortModel={sortModel}
          onSortModelChange={(model) => {
            if (model?.[0]?.sort === 'asc') {
              setSortModel([{ field: 'index', sort: 'desc' }]);
            } else {
              setSortModel(model);
            }
          }}
          sx={datagridSx}
          rows={rows?.map((item) => ({ ...item, index: Number(item?.index) }))}
          disableColumnMenu
          columns={columns}
          getRowClassName={getRowClassName}
          paginationMode="server"
          rowCount={materialData?.data?.[0]?.total_count || 0}
          pageSizeOptions={[10, 20, 30, 40, 50, 60, 70]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={
            isMaterialDeleting ||
            isLoading ||
            isMaterialFetching ||
            isMaterialLoading
          }
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  typography: 'body1',
                }}
              >
                No material found.
              </Box>
            ),
          }}
        />
      </Paper>

      <AlertPopup
        title={'Delete Material?'}
        description={
          'Are you sure you want to delete this material? All the material information added will be lost. This action cannot be undone.'
        }
        submitButtonText="Confirm"
        isOpen={isAlertOpen}
        handleClose={() => {
          setConfirmValue('');
          setIsAlertOpen(false);
        }}
        isConfirmLoading={isMaterialDeleting}
        isConfirmDisabled={confirmValue.toLowerCase() !== 'delete'}
        onConfirm={handleDeleteMaterial}
      >
        <Box px={3}>
          <Typography
            fontWeight={'bold'}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            Please type in DELETE to confirm
          </Typography>
          <TextField
            fullWidth
            value={confirmValue}
            onChange={(e) => {
              setConfirmValue(e.target.value);
            }}
            placeholder="Type to confirm"
            id="outlined-basic"
            variant="outlined"
            sx={{
              'mt': 1,
              '& .MuiInputBase-root': {
                height: 50,
                backgroundColor: 'white',
              },
            }}
          />
        </Box>
      </AlertPopup>

      <ProjectPopup
        desc={
          'Please make sure to verify the material details properly before saving.'
        }
        isOpen={addEditPopup}
        onClose={() => {
          setAddEditPopup(false);
          setSelectedRow({});
          reset();
        }}
        title={`${selectedRow?.id ? 'Edit' : 'Add'} Material`}
        submitButtonText={!selectedRow?.id ? 'Add' : 'Save'}
        paperStyles={{
          maxWidth: '600px',
          width: '600px',
          minHeight: '500px',
        }}
        isConfirmDisabled={!isValid || !isDirty}
        isConfirmLoading={isLoading}
        childComponent={
          <Stack>
            <Divider sx={{ mb: 1 }} />
            <Stack
              gap={2}
              sx={{
                p: 1,
                overflowY: 'auto',
                height: '400px',
              }}
            >
              <DynamicForm
                register={register}
                errors={errors}
                fields={selectedRow?.fields}
              />
            </Stack>
          </Stack>
        }
        onConfirm={handleSaveChanges}
      />

      <Dialog
        open={isUploadDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            Upload CSV
            <Button
              sx={{
                gap: 1,
                color: 'green',
              }}
              onClick={() => {
                FileSaver.saveAs(
                  `${process.env.PUBLIC_URL}/Sample-File.csv`,
                  'Sample-File.csv'
                );
              }}
            >
              <Typography>Download Sample File</Typography>
              <DownloadIcon sx={{ mt: 0.5 }} fontSize="small" />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!csvData?.length ? (
            <Box
              component="label"
              htmlFor="file-upload"
              sx={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'justifyContent': 'center',
                'border': '2px dashed #1976d2',
                'padding': '20px',
                'textAlign': 'center',
                'cursor': 'pointer',
                'width': '100%',
                'maxWidth': '500px',
                'minHeight': '200px',
                'margin': 'auto',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              <CloudUploadIcon sx={{ mb: 2, color: '#1976d2' }} />
              <Typography sx={{ color: '#1976d2', fontSize: 16 }}>
                Upload File
              </Typography>
              <input
                type="file"
                accept=".csv"
                id="file-upload"
                onChange={handleFileChange}
                ref={csvRef}
                style={{ display: 'none' }}
              />
            </Box>
          ) : (
            <Stack
              sx={{
                maxWidth: '500px',
                margin: 'auto',
              }}
            >
              <Divider />
              <Stack
                direction="row"
                sx={{
                  m: 3,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction="row" gap={0.5}>
                  <InsertDriveFileIcon />
                  <Tooltip title={fileName} arrow>
                    <Typography
                      sx={{
                        maxWidth: '250px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {fileName}
                    </Typography>
                  </Tooltip>
                </Stack>
                <IconButton
                  onClick={() => {
                    setFileName('');
                    setCsvData([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {[
            {
              title: 'Cancel',
              onClick: () => {
                setIsUploadDialogOpen(false);
              },
            },
            {
              title: 'Import',
              onClick: () => {
                handleImportCsvFile();
                setIsUploadDialogOpen(false);
              },
              props: {
                disabled: !fileName,
              },
            },
          ].map((btn) => (
            <Button
              key={btn.title}
              onClick={btn?.onClick}
              color="primary"
              sx={{
                fontSize: '18px',
              }}
              {...btn?.props}
            >
              {btn?.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>

      {/* Image View Dialog */}
      <Dialog
        open={openImgDialog}
        onClose={handleCloseImgDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            {imageUrl ? 'Update' : 'Upload'} Image
          </Stack>
        </DialogTitle>
        <DialogContent style={{ position: 'relative' }}>
          <>
            {imageSrc ? (
              <Box
                component="img"
                src={imageSrc}
                alt="creamCabinet"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '250px',
                  objectFit: 'contain',
                  marginBottom: 2,
                  borderRadius: 1,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                }}
              />
            ) : (
              <Box
                component="label"
                htmlFor="file-upload"
                sx={{
                  'display': 'flex',
                  'flexDirection': 'column',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'border': '2px dashed #1976d2',
                  'padding': '20px',
                  'textAlign': 'center',
                  'cursor': 'pointer',
                  'width': '100%',
                  'maxWidth': '500px',
                  'minHeight': '200px',
                  'margin': 'auto',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <CloudUploadIcon sx={{ mb: 2, color: '#1976d2' }} />
                <Typography sx={{ color: '#1976d2', fontSize: 16 }}>
                  Upload Image
                </Typography>
                <input
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  id="file-upload"
                  onChange={handleImgFileChange}
                  ref={imageRef}
                  style={{ display: 'none' }}
                />
              </Box>
            )}
          </>
        </DialogContent>
        <DialogActions>
          {[
            {
              title: 'Cancel',
              onClick: () => {
                setOpenImgDialog(false);
              },
            },
            {
              title: imageUrl ? 'Update' : 'Save',
              onClick: handleUploadImg,
              props: {
                disabled: !imageSrc,
                loading: isImgUploadLoading,
              },
            },
          ].map((btn) => (
            <LoadingButton
              key={btn.title}
              onClick={btn?.onClick}
              color="primary"
              {...btn?.props}
              autoFocus
              sx={{
                fontSize: '18px',
              }}
            >
              {btn?.title}
            </LoadingButton>
          ))}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
