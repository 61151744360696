import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import debounce from 'lodash.debounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AlertPopup from 'components/alert-popup/AlertPopup';
import ButtonSx from 'components/button/ButtonSx';
import ToolTipSx from 'components/tooltip/Tooltip';
import useAuth from 'context/AuthContext';
import { datagridSx } from 'pages/orders/Orders';
import { useGetCustomerQuery } from 'store/apis/customer';
import {
  useDeleteCabinetMutation,
  useGetCabinetsByCategoryQuery,
  useRestoreCabinetMutation,
} from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
import { queryParamsBuilder } from 'utils/commonFunctions';
import { cabinetsOptions } from 'utils/constants';
import { useNotifyToast } from 'utils/useNotifyToast';

import styles from './cabinetDetails.module.css';
import ImportCSV from './common/ImportCsv';

const generalColumns = [
  { field: 'index', label: 'Index', width: 300 },
  { field: 'code', label: 'Code Name', width: 250 },
  { field: 'shortName', label: 'Short Name', width: 400 },
  { field: 'longName', label: 'Long Name', flex: 1 },
];

export default function CabinetDetails() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [selectedCabId, setSelectedCabId] = React.useState('');
  const [selectedRestoreCabId, setSelectedRestoreCabId] = React.useState('');

  const [confirmValue, setConfirmValue] = React.useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const cabinetType = searchParams.get('type') || 'base';
  const viewType = searchParams.get('view') || 'list';

  const [searchText, setSearchText] = React.useState('');
  const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false);

  const [deleteCabinet, { isLoading, isSuccess, isError, error }] =
    useDeleteCabinetMutation();

  const [
    restoreCabinet,
    {
      isLoading: isRestoreCabLoading,
      isSuccess: isRestoreCabSuccess,
      isError: isRestoreCabError,
      error: restoreCabError,
    },
  ] = useRestoreCabinetMutation();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = React.useState([
    { field: 'index', sort: 'asc' },
  ]);

  // api Calls
  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const {
    data: cabinetData,
    isLoading: isCabinetLoading,
    isFetching: isCabinetFetching,
  } = useGetCabinetsByCategoryQuery({
    ...paginationModel,
    page: paginationModel.page + 1,
    type: cabinetType,
    search: searchText,
    orderBy: sortModel?.[0]?.sort || 'asc',
    viewType,
  });

  React.useEffect(() => {
    if (user && user?.role !== 'ADMIN') {
      navigate('/');
    }
  }, [navigate, user]);

  const columns = React.useMemo(
    () =>
      [
        ...generalColumns.map((col) => ({
          field: col.field,
          headerName: col.label,
          sortable: col.field === 'index',
          width: col.width || undefined,
          flex: col.flex || undefined,
          sortComparator:
            col.field === 'index'
              ? (v1, v2) => Number(v1) - Number(v2)
              : undefined,
          // align: 'center',
          // headerAlign: 'center',
          renderCell: (params) => {
            return (
              <Stack
                direction="row"
                justifyContent="left"
                spacing={2}
                alignItems="center"
                height={'100%'}
              >
                <Tooltip
                  title={`${params?.row?.[col.field]}`}
                  placement="top"
                  arrow
                >
                  <Typography
                    noWrap
                    sx={{
                      // maxWidth: '250px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {params?.row?.[col.field]}
                  </Typography>
                </Tooltip>
              </Stack>
            );
          },
        })),
        ...[
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ id, row }) => {
              if (row?.isDeleted) {
                return (
                  <Tooltip title="Restore cabinet">
                    <IconButton
                      onClick={() => {
                        setSelectedRestoreCabId(id);

                        restoreCabinet(id);
                      }}
                    >
                      <RestoreIcon
                        sx={{ color: (theme) => theme.palette.info.light }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              }
              return (
                <>
                  <Tooltip title="View/Edit Details">
                    <IconButton
                      onClick={() => {
                        navigate(
                          `/cabinets/${id}${queryParamsBuilder({
                            type: cabinetType,
                          })}`
                        );
                      }}
                    >
                      <BorderColorIcon
                        sx={{ color: (theme) => theme.palette.success.light }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Cabinet">
                    <IconButton
                      onClick={() => {
                        setSelectedCabId(id);
                        setIsAlertOpen(true);
                      }}
                    >
                      <DeleteIcon
                        sx={{ color: (theme) => theme.palette.error.dark }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        ],
      ] || [],
    [cabinetType, navigate, restoreCabinet]
  );

  const notifyToast = useNotifyToast();

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      setSelectedCabId('');
      notifyToast('Cabinet deleted successfully', 'cabinetDelete', 'success');
    } else if (isError && error) {
      notifyToast(
        error?.data?.errorMessage || 'Something went wrong',
        'cabinetDelete',
        'error'
      );
    }
  }, [error, isError, isLoading, isSuccess, notifyToast]);

  React.useEffect(() => {
    if (!isRestoreCabLoading && isRestoreCabSuccess) {
      notifyToast('Cabinet restored successfully', 'cabinetRestore', 'success');
      setSelectedRestoreCabId('');
    } else if (isRestoreCabError && restoreCabError) {
      notifyToast(
        restoreCabError?.data?.errorMessage || 'Something went wrong',
        'cabinetRestore',
        'error'
      );
    }
  }, [
    isRestoreCabError,
    isRestoreCabLoading,
    isRestoreCabSuccess,
    notifyToast,
    restoreCabError,
  ]);

  const handleChange = (event, newValue) => {
    setSearchParams({ view: newValue });
  };

  const getRowClassName = (params) => {
    return params.row.isDeleted ? styles.disabledRow : '';
  };

  return (
    <Box sx={{ height: '100%', mx: 2 }}>
      <Grid container mb={2.5} width="100%">
        <Grid item xs={12}>
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              alignItems: { sm: 'center' },
            }}
            justifyContent={'space-between'}
            gap={2}
          >
            <Stack
              direction="row"
              sx={{ gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}
              width={'100%'}
            >
              <Typography
                flexWrap={'wrap'}
                sx={{
                  fontSize: pxToRem(30),
                  fontWeight: 700,
                  mt: { xs: 2, sm: 0 },
                  mr: 1,
                }}
              >
                Cabinets
              </Typography>
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { sm: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Selected Cabinets
                </InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cabinetType}
                  label="Selected Cabinets"
                  onChange={(e) => {
                    setSearchParams({ type: e.target.value, view: viewType });
                  }}
                >
                  {cabinetsOptions?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                onChange={debounce((e) => {
                  setSearchText(e.target.value);
                }, 300)}
                placeholder="Search"
                sx={{
                  width: '100%',
                  input: {
                    'borderRadius': '2px',
                    '&::placeholder': {
                      opacity: 1,
                    },
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
              width={'100%'}
              justifyContent={'end'}
            >
              <ButtonSx
                startIcon={<AddIcon />}
                buttonText={
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Add Cabinet
                  </Typography>
                }
                onButtonPress={() => {
                  navigate(
                    `/add-cabinet${queryParamsBuilder({ type: cabinetType })}`
                  );
                }}
                isLoading={false}
                sx={{
                  borderRadius: '5px',
                  height: 50,
                }}
              />
              <ImportCSV
                buttonText={
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Import Cabinets
                  </Typography>
                }
                isUploadDialogOpen={isUploadDialogOpen}
                handleClose={() => setIsUploadDialogOpen(false)}
                setIsUploadDialogOpen={setIsUploadDialogOpen}
                cabinetType={cabinetType}
                onImport={(submitFunction, csvData) => {
                  submitFunction();
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Box width={'100%'}>
        <Tabs
          value={viewType}
          onChange={handleChange}
          // indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            'backgroundColor': '#E5E5E5',
            'mb': 2,
            'width': '100%',
            '& .MuiTabs-indicator': {
              display: 'none', // Hides the indicator
            },
          }}
        >
          <Tab
            sx={{
              '&.Mui-selected': {
                fontWeight: 'bold',
                backgroundColor: 'lightgrey',
              },
            }}
            value="list"
            label="List"
          />
          <Tab
            sx={{
              '&.Mui-selected': {
                fontWeight: 'bold',
                backgroundColor: 'lightgrey',
              },
            }}
            value="grid"
            label="Grid"
          />
        </Tabs>
      </Box>
      {viewType === 'grid' && (
        <Grid container spacing={2}>
          {isCabinetLoading || isCabinetFetching ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="60vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!cabinetData?.data.length ? (
                <Stack
                  width={'100%'}
                  my={15}
                  minHeight={150}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Typography>No Cabinet Found</Typography>
                </Stack>
              ) : (
                cabinetData?.data?.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3} lg={2}>
                    <Box
                      sx={{
                        'position': 'relative',
                        'width': '100%',
                        '&:hover .delete-icon': {
                          opacity: 1, // Show the icon when the parent is hovered
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={
                          (!item?.img ? placeHolderImage : item?.img) ||
                          placeHolderImage
                        }
                        alt="Cabinet Image"
                        sx={{
                          width: '100%',
                          height: {
                            xs: '150px',
                            sm: '200px',
                            md: '220px',
                            lg: '250px',
                          },
                          minHeight: '250px',
                          objectFit: 'contain',
                          position: 'relative',
                        }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                        }}
                      />
                      {item?.isDeleted ? (
                        <Tooltip title="Restore Cabinet">
                          <IconButton
                            onClick={() => {
                              setSelectedRestoreCabId(item?.id);
                              restoreCabinet(item?.id);
                            }}
                            className="delete-icon"
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              zIndex: 1,
                              opacity: 0,
                              transition: 'opacity 0.3s ease',
                            }}
                          >
                            <RestoreIcon
                              sx={{ color: (theme) => theme.palette.info.dark }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Delete Cabinet">
                          <IconButton
                            onClick={() => {
                              setSelectedCabId(item?.id);
                              setIsAlertOpen(true);
                            }}
                            className="delete-icon"
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              zIndex: 1,
                              opacity: 0,
                              transition: 'opacity 0.3s ease',
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: (theme) => theme.palette.error.dark,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>

                    <ToolTipSx title={item?.shortName}>
                      <Typography
                        noWrap
                        variant="subtitle1"
                        fontWeight={500}
                        pt={2}
                        sx={{ textAlign: 'center' }}
                      >
                        {item?.shortName}
                      </Typography>
                    </ToolTipSx>
                    <ToolTipSx title={item?.longName}>
                      <Typography
                        variant="body2"
                        fontWeight={300}
                        py={0.5}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          minHeight: 48,
                          textAlign: 'center',
                          heigh: '100%',
                          fontSize: '15px',
                        }}
                      >
                        {item?.longName}
                      </Typography>
                    </ToolTipSx>

                    <Stack justifyContent={'center'} direction={'row'} gap={2}>
                      <ButtonSx
                        isLoading={
                          selectedRestoreCabId === item?.id &&
                          isRestoreCabLoading
                        }
                        disabled={item?.isDeleted}
                        sx={{
                          px: 4,
                          fontWeight: 700,
                          width: '70%',
                          mt: 1,
                          mb: 2,
                          fontSize: { xs: '14px' },
                        }}
                        buttonText="Edit Details"
                        onButtonPress={() => {
                          navigate(
                            `/cabinets/${item?.id}${queryParamsBuilder({
                              type: cabinetType,
                            })}`
                          );
                        }}
                      />
                    </Stack>
                  </Grid>
                ))
              )}
            </>
          )}
        </Grid>
      )}
      {viewType === 'list' && (
        <Paper
          elevation={0}
          sx={{
            'height': 'auto',

            'width': '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
            'mb': 10,
            'zIndex': 0,
          }}
        >
          <DataGrid
            sortModel={sortModel}
            onSortModelChange={(model) => {
              if (model?.[0]?.sort === 'asc') {
                setSortModel([{ field: 'index', sort: 'desc' }]);
              } else {
                setSortModel(model);
              }
            }}
            sx={datagridSx}
            rows={cabinetData?.data || []}
            disableColumnMenu
            columns={columns}
            getRowClassName={getRowClassName}
            paginationMode="server"
            rowCount={cabinetData?.totalCount || 0}
            pageSizeOptions={[10, 20, 30, 40, 50, 60, 70]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={isCabinetLoading || isCabinetFetching}
            slots={{
              noRowsOverlay: () => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    typography: 'body1',
                  }}
                >
                  No Cabinet found.
                </Box>
              ),
            }}
          />
        </Paper>
      )}
      <AlertPopup
        title={'Delete Cabinet?'}
        description={
          'Are you sure you want to delete this cabinet? All the cabinet information added will be lost. This action cannot be undone.'
        }
        submitButtonText="Confirm"
        isOpen={isAlertOpen}
        handleClose={() => {
          setIsAlertOpen(false);
          setSelectedCabId('');
          setConfirmValue('');
        }}
        isConfirmLoading={isLoading}
        isConfirmDisabled={confirmValue.toLowerCase() !== 'delete'}
        onConfirm={() => {
          deleteCabinet(selectedCabId);
          setConfirmValue('');
          setIsAlertOpen(false);
        }}
      >
        <Box px={3}>
          <Typography
            fontWeight={'bold'}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            Please type in DELETE to confirm
          </Typography>
          <TextField
            fullWidth
            value={confirmValue}
            onChange={(e) => {
              setConfirmValue(e.target.value);
            }}
            placeholder="Type to confirm"
            id="outlined-basic"
            variant="outlined"
            sx={{
              'mt': 1,
              '& .MuiInputBase-root': {
                height: 50,
                backgroundColor: 'white',
              },
            }}
          />
        </Box>
      </AlertPopup>
    </Box>
  );
}
