import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { RichTreeView, useTreeViewApiRef } from '@mui/x-tree-view';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AccordionCustom from 'components/accordian/AccordionCustom';
import { useGetAllMaterialsQuery } from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
import { queryParamsBuilder, sortNestedData } from 'utils/commonFunctions';
import { FILTERS } from 'utils/constants';
function CommonFilterTreeView({
  onCheck = () => {},
  setSelectedItems,
  selectedItems,
}) {
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();
  const [isExpanded, setIsExpanded] = useState({
    matTypes: true,
  });

  const handleChangeExpanded = (panel) => () => {
    setIsExpanded((prevState) => {
      return {
        ...prevState,
        [panel]: !prevState[panel],
      };
    });
  };

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    onCheck(newSelectedItems);
    setSelectedItems(newSelectedItems?.map((item) => item.id));
  };

  return (
    <>
      {FILTERS?.map((filter, index) => {
        return (
          <Box
            key={index}
            sx={{
              'minWidth': 290,
              '& .Mui-selected': {
                backgroundColor: 'transparent !important', // Removes the background
              },
            }}
          >
            <AccordionCustom
              title={
                <Typography fontSize={pxToRem(19)} fontWeight={500}>
                  {filter?.title}
                </Typography>
              }
              expanded={isExpanded.adjustments}
              handleChange={handleChangeExpanded('matTypes')}
              sx={{
                '& .MuiAccordionSummary-root': {
                  background: '#EEEEEE',
                },
                '& .MuiCollapse-root': {
                  background: '#EEEEEE',
                },
                '& .MuiBox-root': {
                  minHeight: '0px',
                },
              }}
            >
              <RichTreeView
                multiSelect
                checkboxSelection
                apiRef={apiRef}
                items={filter?.children}
                selectedItems={selectedItems}
                onSelectedItemsChange={(event, selectedIds) => {
                  const selectedLabels = selectedIds
                    .map((id) => {
                      const matchedItem = FILTERS?.flatMap(
                        (item) => item.children || []
                      ).find((item) => item.id === id);
                      return matchedItem ? matchedItem : null;
                    })
                    .filter(Boolean);

                  handleSelectedItemsChange(event, selectedLabels);
                }}
                onItemSelectionToggle={handleItemSelectionToggle}
                sx={{
                  '.MuiTreeItem-content': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    // ...(filter.title === 'Colors and Textures' && {
                    //   '.MuiSvgIcon-root': {
                    //     display: 'none',
                    //   },
                    // }),
                  },
                  // ...(filter.title === 'Colors and Textures' && {
                  //   '.MuiCheckbox-root': (theme) => ({
                  //     'borderRadius': '50%', // Make the checkbox round
                  //     'border': '2px solid', // Add a border
                  //     'borderColor': 'gray', // Conditional border color
                  //     'backgroundColor': 'transparent', // Conditional background
                  //     'width': '26px',
                  //     'height': '24px',
                  //     '&:hover': {
                  //       backgroundColor: 'rgba(0, 0, 0, 0.08)', // Hover effect
                  //     },
                  //   }),
                  // }),
                }}
              />
            </AccordionCustom>
            <Divider />
          </Box>
        );
      })}
    </>
  );
}

CommonFilterTreeView.propTypes = {
  handleClearAll: PropTypes.any,
  onCheck: PropTypes.func,
  selectedItems: PropTypes.any,
  setSelectedItems: PropTypes.func,
};

const handleFilterData = (chipData, matData) => {
  const regex = new RegExp(chipData?.map((item) => item.label).join('|'), 'i');

  let filteredChildren = [];
  const filteredData = matData
    .map((item) => {
      if (item.children) {
        filteredChildren = item.children.filter(
          (child) =>
            regex.test(child.label) ||
            regex.test(child.id) ||
            regex.test(child.desc) ||
            regex.test(item.id) ||
            regex.test(item.label)
        );
      }

      if (
        regex.test(item.id) ||
        regex.test(item.label) ||
        regex.test(item.desc) ||
        filteredChildren.length > 0
      ) {
        return { ...item, children: filteredChildren };
      }

      return null;
    })
    .filter(Boolean);

  return filteredData;
};

const SelectMaterial = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');

  const navigate = useNavigate();
  const { materialType } = useParams();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [matData, setMatData] = React.useState([]);
  const [filteredMat, setFilteredMat] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [chipData, setChipData] = React.useState([]);
  const { data, isLoading, isFetching } = useGetAllMaterialsQuery();

  const handleDelete = (chipToDelete) => () => {
    const filteredChipData = chipData.filter(
      (chip) => chip.key !== chipToDelete.key
    );

    if (!filteredChipData.length) {
      setFilteredMat(matData);
    } else {
      // code to  filter as per the Filters
      const regex = new RegExp(
        filteredChipData?.map((item) => item.label).join('|'),
        'i'
      );

      const filteredData = matData
        .map((item) => {
          const filteredChildren = item.children.filter(
            (child) => regex.test(child.label) || regex.test(child.id)
          );

          if (
            regex.test(item.id) ||
            regex.test(item.label) ||
            filteredChildren.length > 0
          ) {
            return { ...item, children: filteredChildren };
          }

          return null;
        })
        .filter(Boolean);
      setFilteredMat(filteredData);
    }
    setChipData(filteredChipData);
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item !== chipToDelete.key)
    );
  };

  const handleFilterMatSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    if (!searchText.trim()) {
      if (chipData?.length) {
        const filteredData = handleFilterData(chipData, matData);

        setFilteredMat(filteredData);
      }
      return;
    }

    const regex = new RegExp(searchText, 'i');
    const searchResult = filteredMat?.reduce((acc, item) => {
      const obj = { ...item };

      if (item?.children) {
        obj.children = item.children.filter((subItem) =>
          regex.test(subItem?.label)
        );
        if (obj.children.length > 0) {
          acc.push(obj);
        }
      } else if (regex.test(item?.label)) {
        acc.push(obj);
      }

      return acc;
    }, []);

    setFilteredMat(searchResult);
  };

  React.useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const caseType = materialType?.split(' ')?.[0];
      const materialsKey = `${caseType?.toLowerCase()}Materials`;

      if (caseType === 'Door') {
        const groupedData = data?.[materialsKey]?.reduce((acc, item) => {
          let parentName = 'Misc Doors';
          if (/Melamine\b/.test(item.name)) {
            parentName = 'Melamine/TFL Doors';
          } else if (/RTF\b/.test(item.name)) {
            parentName = 'Thermofoil/RTF Doors';
          } else if (/Standard\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Standard Colors';
          } else if (/Custom\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Custom Colors';
          }

          const existingGroup = acc.find((group) => group.label === parentName);
          if (existingGroup) {
            existingGroup.children.push({
              id: item.id,
              label: item.name,
              img: item.img,
            });
          } else {
            acc.push({
              id: parentName,
              label: parentName,
              children: [
                {
                  id: item.id,
                  label: item.name,
                  img: item.img,
                },
              ],
            });
          }

          return acc;
        }, []);

        setMatData(groupedData);
        setFilteredMat(groupedData);
      } else {
        const arr = data?.[materialsKey]?.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        setMatData(arr);
        setFilteredMat(arr);
      }
    }
  }, [data, isFetching, isLoading, materialType]);
  return (
    <Stack
      alignItems={'center'}
      sx={{
        p: 3,
        height: { xs: '100%', md: '100vh' },
        overflow: 'hidden',
      }}
    >
      <Stack
        gap={4}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
          height: '100%',
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            flex: 1,
            height: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              bgcolor: '#EEEEEE',
              p: 3,
              py: 1,
              height: 'calc(100% - 50px)', // Adjust for title height
              position: 'sticky',
              top: 0, // Sticks to the top
              overflowY: 'auto', // Makes the Filter Tree scrollable if needed
            }}
          >
            <Stack
              sx={{
                backgroundColor: '#000000',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
                p: 1,
              }}
            >
              <Typography
                sx={{
                  // fontWeight: 600,
                  fontSize: 20,
                  color: 'white',
                }}
              >
                FILTERS
              </Typography>
            </Stack>
            <CommonFilterTreeView
              setFilteredMat={setFilteredMat}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onCheck={(selectedData) => {
                if (!selectedData.length) {
                  setFilteredMat(matData);
                } else {
                  const filteredData = handleFilterData(selectedData, matData);
                  setFilteredMat(filteredData);
                }

                setChipData((prev) => {
                  const updatedChipData = prev.filter((chip) =>
                    selectedData?.map((item) => item.id).includes(chip.key)
                  );

                  selectedData.forEach((item) => {
                    if (!updatedChipData.some((chip) => chip.key === item.id)) {
                      updatedChipData.push({
                        key: item.id,
                        label: item.label,
                      });
                    }
                  });

                  return updatedChipData;
                });
              }}
            />
          </Box>
        </Stack>
        <Stack
          gap={2}
          sx={{
            'flex': 3,
            'height': '100%',
            'overflowY': 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
              background: 'transparent',
            },
            'msOverflowStyle': 'none',
            'scrollbarWidth': 'none',
          }}
        >
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: 36, mb: 2 }}
          >
            {materialType}
          </Typography>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{
              pl: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            gap={1}
          >
            <Stack direction={'row'}>
              <Typography>Showing </Typography>
              <Typography sx={{ fontWeight: 700 }}>
                &nbsp;
                {materialType !== 'Door Material'
                  ? filteredMat?.length
                  : filteredMat?.reduce((acc, mat) => {
                      acc += mat.children?.length;
                      return acc;
                    }, 0)}
                &nbsp;
              </Typography>
              materials
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={'center'}
              gap={1}
              sx={{ width: { xs: '100%', md: 'inherit' }, pl: 2 }}
            >
              <FormControl
                sx={{
                  minWidth: '30%',
                  width: { xs: '100%', md: '250px' },
                }}
              >
                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                <Select
                  sx={{ borderRadius: 0 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={'a-z'}
                  label="Sort"
                  onChange={(e) => {
                    const sortedData = sortNestedData(
                      filteredMat,
                      e.target.value
                    );
                    setFilteredMat(sortedData);
                  }}
                >
                  {[
                    { id: 'a-z', label: 'Material Name (A-Z)' },
                    { id: 'z-a', label: 'Material Name (Z-A)' },
                  ]?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                onChange={handleFilterMatSearch}
                value={searchText}
                placeholder="Search material name"
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: 'white',
                    maxWidth: { xs: '100%', md: '100%' },
                    width: '100%',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
          <Divider />

          <Stack
            direction="row"
            sx={{
              pl: 2,
              gap: 2,
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {chipData?.map((cData, index) => (
              <Chip
                key={index}
                label={cData.label}
                onDelete={handleDelete(cData)}
                sx={{
                  maxWidth: '150px',
                }}
              />
            ))}
            {!!chipData?.length && (
              <Typography
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'red',
                }}
                onClick={() => {
                  setSelectedItems([]);
                  setChipData([]);
                  setFilteredMat(matData);
                }}
              >
                Clear all
              </Typography>
            )}
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{
              ...(materialType === 'Door Material' && { ml: 1 }),
              p: 2,
            }}
          >
            {isLoading && (
              <Box
                py={8}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
              >
                <CircularProgress size={25} />
              </Box>
            )}
            {!isLoading && filteredMat && !filteredMat?.length && (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography>No materials found</Typography>
              </Stack>
            )}

            {filteredMat?.map((mat, index) => {
              if (mat?.children?.length) {
                return (
                  <Box key={mat?.id} sx={{ width: '100%' }}>
                    {index !== 0 && <Divider sx={{ width: '100%', my: 2 }} />}
                    <Typography
                      sx={{ fontWeight: 'bold', my: 2, fontSize: '20px' }}
                    >
                      {mat?.label}
                    </Typography>
                    <Grid container spacing={2}>
                      {mat?.children?.map((item) => (
                        <Grid
                          key={item?.id}
                          item
                          xs={4}
                          md={2}
                          lg={2}
                          sx={{ p: 1 }}
                        >
                          <img
                            onClick={() => {
                              navigate(
                                `/select-material/${materialType}/${item?.id}${
                                  from ? queryParamsBuilder({ from }) : ''
                                }`
                              );
                            }}
                            src={placeHolderImage}
                            alt="logo"
                            width={'100%'}
                            style={{
                              height: 'auto',
                              objectFit: 'contain',
                              border: '1px solid #EEEEEE',
                              cursor: 'pointer',
                            }}
                          />
                          <Tooltip title={item?.label} placement="top" arrow>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: '200px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                fontSize: { xs: '10px', md: '14px' },
                              }}
                            >
                              {item?.label}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              }
              return (
                <Grid key={mat?.id} item xs={4} md={2} lg={2}>
                  <img
                    onClick={() => {
                      navigate(
                        `/select-material/${materialType}/${mat?.id}${
                          from ? queryParamsBuilder({ from }) : ''
                        }`
                      );
                    }}
                    src={placeHolderImage}
                    alt="logo"
                    width={'100%'}
                    style={{
                      height: 'auto',
                      objectFit: 'contain',
                      border: '1px solid #EEEEEE',
                      cursor: 'pointer',
                    }}
                  />
                  <Tooltip title={mat?.label} placement="top" arrow>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: '180px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                      }}
                    >
                      {mat?.label}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectMaterial;
