import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';

import ButtonSx from 'components/button/ButtonSx';
import { useAddCabinetMutation } from 'store/apis/order';
import { cabinetDetails } from 'utils/constants';
import { useNotifyToast } from 'utils/useNotifyToast';

export const additionalFields = [
  'nameP',
  'descP',
  'typeP',
  'minValueP',
  'maxValueP',
  'FormulaP',
];
function ImportCSV({
  isUploadDialogOpen,
  handleClose,
  setIsUploadDialogOpen,
  cabinetType,
  buttonText,
  onImport = () => {},
}) {
  const csvRef = React.useRef(null);
  const [csvData, setCsvData] = React.useState([]);
  const [fileName, setFileName] = React.useState('');
  const [
    addCabinet,
    {
      data: addData,
      isSuccess: isAddSuccess,
      isError: isAddError,
      error: addError,
    },
  ] = useAddCabinetMutation();

  const notifyToast = useNotifyToast();

  const handleImportCsvFile = () => {
    const payload = csvData.map((csvRow) => {
      const payload = {
        ...csvRow,
        type: cabinetType.toUpperCase(),
        rightEnd: 0,
        leftEnd: 0,
        hinging: 'OPEN',
      };

      // Convert "Yes", "No", and "none" to appropriate values
      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (typeof value === 'string') {
          const lowerCaseValue = value.toLowerCase();
          if (lowerCaseValue === 'yes') {
            payload[key] = true;
          } else if (lowerCaseValue === 'no') {
            payload[key] = false;
          } else if (lowerCaseValue === 'none') {
            payload[key] = null;
          }
        }
      });

      // Construct adjustmentParameters array
      const adjustmentParameters = [];
      for (let i = 1; i <= 6; i++) {
        const id = payload[`${additionalFields[0]}${i}`];
        const desc = payload[`${additionalFields[1]}${i}`];
        const type = payload[`${additionalFields[2]}${i}`];
        const minValue = payload[`${additionalFields[3]}${i}`];
        const maxValue = payload[`${additionalFields[4]}${i}`];
        const value = payload[`${additionalFields[5]}${i}`];

        // Check if all fields are "N/A"
        const allFieldsNA = [id, desc, type, minValue, maxValue, value].every(
          (field) => field === 'none' || field === 'null' || field === null
        );

        if (
          !allFieldsNA &&
          (id || desc || type || minValue || maxValue || value)
        ) {
          adjustmentParameters.push({
            id,
            desc,
            type,
            minValue,
            maxValue,
            value,
          });
        }

        // Remove additional parameter fields from payload
        delete payload[`${additionalFields[0]}${i}`];
        delete payload[`${additionalFields[1]}${i}`];
        delete payload[`${additionalFields[2]}${i}`];
        delete payload[`${additionalFields[3]}${i}`];
        delete payload[`${additionalFields[4]}${i}`];
        delete payload[`${additionalFields[5]}${i}`];
      }

      // Convert adjustmentParameters to JSON string or set to null
      payload.adjustmentParameters =
        adjustmentParameters.length > 0
          ? JSON.stringify(adjustmentParameters)
          : null;

      return payload;
    });

    return payload;
  };

  React.useEffect(() => {
    if (isAddSuccess) {
      notifyToast(
        addData?.message || 'Cabinet Details Imported Successfully!',
        'cabinetImportCsvFile',
        'success'
      );
    } else if (isAddError) {
      const errorMessage =
        addError?.data?.message || 'Failed to import cabinets.';
      notifyToast(errorMessage, 'cabinetAddError', 'error');
    }
  }, [
    addData?.message,
    addError?.data?.message,
    isAddError,
    isAddSuccess,
    notifyToast,
  ]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;

      if (!csvData) {
        notifyToast(
          'The uploaded file is empty. Please upload a valid CSV file.',
          'fileEmptyError',
          'error'
        );
        return;
      }

      let lines = csvData.split('\n');

      function replaceEmptyWithNull(csvString) {
        // Match empty fields and replace with 'null'
        const regex = /(?<=,)(?=,)|(?<=,)(?=$)|(?<=^)(?=,)/g;

        // Replace matches with 'null'
        return csvString.replace(regex, 'null');
      }

      const headers = lines[1]
        ?.split(',')
        ?.map((item) => item.toLowerCase().replace(/\s+/g, ''));

      lines = lines
        ?.map((line) => {
          return replaceEmptyWithNull(line);
        })
        .filter((item) => item);

      const regex = /"([^"]*)"|([^",]+)/g;

      // Parse each line, handling commas inside quotes
      const rows = lines
        .slice(1) // Skip the header row
        .map((row) => {
          let result = [];
          let match;

          while ((match = regex.exec(row)) !== null) {
            if (match[1]) {
              result.push(match[1]);
            } else {
              result.push(match[2] || '');
            }
          }

          return result;
        });

      // Expected headers validation
      const expectedHeaders = [
        ...cabinetDetails
          .map((section) =>
            section.fields.map((field) => field.id.toLowerCase())
          )
          .flat(),
        'img',
      ];

      const isHeaderValid = headers.every((header) => {
        return expectedHeaders.includes(header);
      });

      if (!isHeaderValid) {
        notifyToast(
          'CSV headers do not match the expected structure.Please refer sample file',
          'invalidHeaderStructure',
          'error'
        );
        return;
      }

      setFileName(file.name);

      const getInputFieldName = (element) =>
        cabinetDetails?.reduce((acc, item) => {
          item.fields.forEach((field) => {
            if (field.id.toLowerCase() === element) {
              acc = field.id;
            } else if (element === 'img') {
              acc = 'img';
            }
          });
          return acc;
        }, '');

      const newAddedData = rows?.slice(1)?.reduce((acc, item) => {
        const obj = {};

        item?.forEach((subItem, index) => {
          obj[getInputFieldName(headers[index])] =
            subItem?.toLowerCase() === 'yes'
              ? 'Yes'
              : subItem?.toLowerCase() === 'no'
              ? 'No'
              : subItem;
        });

        return [...acc, obj];
      }, []);

      const invalidFields = cabinetDetails.reduce((acc, section) => {
        section.fields
          ?.map((item) => ({ ...item, id: item.id.toLowerCase() }))
          .forEach((field) => {
            // Check if the field belongs to "Additional Parameter" sections
            if (section.title.startsWith('Additional Parameter')) {
              return;
            } else {
              if (newAddedData?.[0]?.[field.id] === 'null') {
                acc.push(field.id);
              }
            }
          });
        return acc;
      }, []);

      if (invalidFields?.length) {
        notifyToast(
          `Empty values are not allowed. Check: ${invalidFields?.join()}`,
          'emptyValues',
          'error'
        );
        return;
      }

      setCsvData(newAddedData);
    };
    csvRef.current.value = '';

    reader.readAsText(file);
  };
  return (
    <>
      <ButtonSx
        buttonText={buttonText || <Typography>Import CSV</Typography>}
        onButtonPress={() => {
          setCsvData([]);
          setFileName('');
          setIsUploadDialogOpen(true);
        }}
        isLoading={false}
        sx={{
          borderRadius: '5px',
          height: 50,
        }}
      />
      <Dialog
        open={isUploadDialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          >
            Upload CSV
            <Button
              sx={{
                gap: 1,
                color: 'green',
              }}
              onClick={() => {
                FileSaver.saveAs(
                  `${process.env.PUBLIC_URL}/Baru-cabinet-sample.csv`,
                  'Sample-File.csv'
                );
              }}
            >
              <Typography>Download Sample File</Typography>
              <DownloadIcon sx={{ mt: 0.5 }} fontSize="small" />
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!csvData?.length ? (
            <>
              <Box
                component="label"
                htmlFor="file-upload"
                sx={{
                  'display': 'flex',
                  'flexDirection': 'column',
                  'alignItems': 'center',
                  'justifyContent': 'center',
                  'border': '2px dashed #1976d2',
                  'padding': '20px',
                  'textAlign': 'center',
                  'cursor': 'pointer',
                  'width': '100%',
                  'maxWidth': '500px',
                  'minHeight': '200px',
                  'margin': 'auto',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                <CloudUploadIcon sx={{ mb: 2, color: '#1976d2' }} />
                <Typography sx={{ color: '#1976d2', fontSize: 16 }}>
                  Upload File
                </Typography>
                <input
                  type="file"
                  accept=".csv"
                  id="file-upload"
                  onChange={handleFileChange}
                  ref={csvRef}
                  style={{ display: 'none' }}
                />
              </Box>
              {buttonText?.props?.children
                ?.toLowerCase()
                ?.includes('single') && (
                <>
                  <Typography sx={{ mt: 1, ml: 3, fontSize: '12px' }}>
                    Note: Please ensure the CSV contains only one cabinet.
                  </Typography>
                </>
              )}{' '}
            </>
          ) : (
            <Stack
              sx={{
                maxWidth: '500px',
                margin: 'auto',
              }}
            >
              <Divider />
              <Stack
                direction="row"
                sx={{
                  m: 3,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction="row" gap={0.5}>
                  <InsertDriveFileIcon />
                  <Tooltip title={fileName} arrow>
                    <Typography
                      sx={{
                        maxWidth: '250px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {fileName}
                    </Typography>
                  </Tooltip>
                </Stack>

                <IconButton
                  onClick={() => {
                    setFileName('');
                    setCsvData([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {[
            {
              title: 'Cancel',
              onClick: handleClose,
            },
            {
              title: 'Import',
              onClick: () => {
                const finalData = handleImportCsvFile();
                onImport(() => {
                  addCabinet(finalData);
                }, csvData);
                handleClose();
              },
              props: {
                disabled: !fileName,
              },
            },
          ].map((btn) => (
            <Button
              key={btn.title}
              onClick={btn?.onClick}
              color="primary"
              sx={{
                fontSize: '18px',
              }}
              {...btn?.props}
            >
              {btn?.title}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
}

ImportCSV.propTypes = {
  buttonText: PropTypes.any,
  cabinetType: PropTypes.string,
  data: PropTypes.any,
  handleClose: PropTypes.func,
  isLoading: PropTypes.any,
  isUploadDialogOpen: PropTypes.any,
  onImport: PropTypes.func,
  onSuccess: PropTypes.func,
  setIsUploadDialogOpen: PropTypes.func,
};

export default ImportCSV;
