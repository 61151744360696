import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddToPhotosSharpIcon from '@mui/icons-material/AddToPhotosSharp';
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';
import CallSplitSharpIcon from '@mui/icons-material/CallSplitSharp';
import CategoryIcon from '@mui/icons-material/Category';
import ContactsIcon from '@mui/icons-material/Contacts';
// import FeaturedPlayListSharpIcon from '@mui/icons-material/FeaturedPlayListSharp';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import HelpIcon from '@mui/icons-material/Help';
import HelpCenterSharpIcon from '@mui/icons-material/HelpCenterSharp';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import ArrowCircleUpSharpIcon from '@mui/icons-material/OpenInBrowserSharp';
// import PaymentIcon from '@mui/icons-material/Payment';
// import PaidIcon from '@mui/icons-material/Paid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';

import cabinetImg from '../assets/img/cabinetIcon.png';
import materialImg from '../assets/img/materialIcon.png';

export const investUrl =
  'https://www.figure.com/invest/listings/6fa98316-2b66-4af8-8b8e-fb9484c84e8b/';

export const aboutLinks = [
  {
    text: 'Our company',
    link: 'https://www.hellobaru.com/about',
  },
  // {
  //   text: 'Team',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Jobs',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Investors',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Invest',
  //   link: 'https://www.figure.com/invest/listings/6fa98316-2b66-4af8-8b8e-fb9484c84e8b/',
  // },
  // {
  //   text: 'Terms of service',
  //   link: 'https://www.barucabinets.com/about',
  // },
  // {
  //   text: 'Privacy policy',
  //   link: 'https://www.barucabinets.com/about',
  // },
];

export const storeLinks = [
  {
    text: 'Base cabinets',
    path: '/cabinet/base-cabinets',
  },
  {
    text: 'Wall cabinets',
    path: '/cabinet/wall-cabinets',
  },
  {
    text: 'Tall cabinets',
    path: '/cabinet/tall-cabinets',
  },
  {
    text: 'Corner cabinets',
    path: '/cabinet/corner-cabinets',
  },
  {
    text: 'Oven cabinets',
    path: '/cabinet/oven-cabinets',
  },
  {
    text: 'Additional Items',
    path: '/cabinet/additional-items',
  },
];

export const supportLinks = [
  {
    text: 'FAQ',
    // path: '/faq',
    link: 'http://hellobaru.com/faq',
  },
  // {
  //   text: 'Shipping',
  // },
  {
    text: 'Request support',
    path: '/request-support',
  },
];

export const authPages = [
  // {
  //   title: 'Invest',
  //   path: investUrl,
  //   icon: PaidIcon,
  // },

  {
    title: 'My Projects',
    path: '/my-projects/drafts',
    icon: LibraryBooksIcon,
  },
  {
    title: 'Help',
    path: '/help',
    icon: HelpCenterSharpIcon,
  },
  {
    title: 'My Account',
    path: '/my-account/account-info',
    icon: PortraitSharpIcon,
  },
  {
    title: 'My Orders',
    path: '/orders',
    icon: HistorySharpIcon,
  },
];

export const unAuthPages = [
  {
    title: 'Help',
    path: '/orders',
    icon: HelpCenterSharpIcon,
  },
  {
    title: 'Login',
    path: '/sign-in',
    icon: LoginSharpIcon,
  },
];

export const adminPages = [
  {
    title: 'Materials',
    path: '/materials',
    icon: () => (
      <img
        src={materialImg}
        alt="Cabinets Icon"
        style={{ width: '25px', height: '25px' }}
      />
    ),
  },
  {
    title: 'Cabinets',
    path: '/cabinets',
    icon: () => (
      <img
        src={cabinetImg}
        alt="Cabinets Icon"
        style={{ width: '25px', height: '25px' }}
      />
    ),
  },
  {
    title: 'Add Customer',
    path: '/add-customer',
    icon: PersonAddIcon,
  },
];

export const doorFilter = ['All', '0', '1', '2', '3', '4'];
export const drawersFilter = ['All', '0', '1', '2', '3', '4', '5', '6'];

export const cabinetSort = [
  'Popularity',
  // 'Price: High to Low',
  // 'Price: Low to High',
  'Name: A-Z',
  'Name: Z-A',
];
export const itemsPerPageFilter = ['All', '24', '48', '96'];

export const cabinetMenu = [
  {
    title: 'Home',
    path: '/',
    icon: HomeSharpIcon,
  },
  // {
  //   title: 'Featured',
  //   path: '/cabinet/featured',
  //   icon: FeaturedPlayListSharpIcon,
  // },
  {
    title: 'Base Cabinets',
    path: '/cabinet/base-cabinets',
    icon: ArrowCircleDownSharpIcon,
  },
  {
    title: 'Wall Cabinets',
    path: '/cabinet/wall-cabinets',
    icon: ArrowCircleUpSharpIcon,
  },
  {
    title: 'Tall Cabinets',
    path: '/cabinet/tall-cabinets',
    icon: ArrowCircleUpSharpIcon,
  },
  {
    title: 'Corner Cabinets',
    path: '/cabinet/corner-cabinets',
    icon: CallSplitSharpIcon,
  },
  {
    title: 'Oven Cabinets',
    path: '/cabinet/oven-cabinets',
    icon: GradeSharpIcon,
  },
  {
    title: 'Additional Items',
    path: '/cabinet/additional-items',
    icon: AddToPhotosSharpIcon,
  },
];

export const helpMenu = [
  {
    title: 'About Baru',
    onClick: () => {
      window.open(links.about, '_blank');
    },
    icon: InfoIcon,
  },
  {
    // path: '/faq',
    link: 'http://hellobaru.com/faq',
    icon: HelpIcon,
    title: 'FAQ',
  },
  {
    path: '/contact-us',
    icon: ContactsIcon,
    title: 'Contact Us',
  },
];

export const accountMenu = [
  {
    path: '/my-account/account-info',
    title: 'Account',
    icon: AccountCircleIcon,
  },
  {
    path: '/my-account/billing',
    icon: LocalShippingIcon,
    title: 'Billing',
  },
  {
    path: '/my-account/shipping',
    icon: LocalShippingIcon,
    title: 'Shipping',
  },
  // {
  //   path: '/my-account/payment',
  //   icon: PaymentIcon,
  //   title: 'Payment',
  // },
  {
    path: '/my-account/default-product-options',
    icon: CategoryIcon,
    title: 'Default product options',
  },
];
export const links = {
  about: 'https://www.hellobaru.com/about',
};

export const handRailNotchOptions = [
  {
    option: 'Yes',
    id: 1,
  },
  {
    option: 'No',
    id: 0,
  },
];

// Add Edit Materials Page constants

export const materialsOptions = [
  { id: 'Case', label: 'Case Material' },
  { id: 'Door', label: 'Door Material' },
];

export const cabinetsOptions = [
  { id: 'base', label: 'Base' },
  { id: 'wall', label: 'Wall' },
  { id: 'tall', label: 'Tall' },
  { id: 'extras', label: 'Additional' },
];

export const removedCols = [
  'createdAt',
  'updatedAt',
  'id',
  'img',
  'total_count',
  'caseMessage',
  'doorMessage',
  'isDeleted',
  'isNew',
  'usage',
];

export const types = {
  dropdown: ['grain', 'outsource'],
  text: ['name', 'usage'],
};

export const expectedHeaders = [
  'Index',
  'Name',
  'MatID',
  'Grain',
  'Out Source',
  'SqFt/ Sheet',
  'Waste',
  'Cost $$/SqFt',
  'Filler $$/SqFt',
  'Panel $$/SqFT',
  'Hinge $$/Bore',
  'Add per Order',
  '3" Crown $$/Piece',
  '4" Crown $$/Piece',
  '3" Base $$/Piece',
  '4" Base $$/Piece',
  'CNC per sheet',
  'MARKUP',
  '1mm $$/LF',
  '2mm $$/LF',
  '3mm $$/LF',
  'Scribe/Shoe $$/Piece',
];

export const headersConversion = {
  index: 'Index',
  name: 'Name',
  materialId: 'MatID',
  grain: 'Grain',
  outsource: 'Out Source',
  sqFtPerSheet: 'SqFt/ Sheet',
  waste: 'Waste',
  costPerSqFt: 'Cost $$/SqFt',
  fillerCostPerSqFt: 'Filler $$/SqFt',
  panelCostPerSqFt: 'Panel $$/SqFT',
  hingeRatePerBore: 'Hinge $$/Bore',
  addPerOrder: 'Add per Order',
  threeCrownRatePerPiece: '3" Crown $$/Piece',
  fourCrownRatePerPiece: '4" Crown $$/Piece',
  threeBaseRatePerPiece: '3" Base $$/Piece',
  fourBaseRatePerPiece: '4" Base $$/Piece',
  cncPerSheet: 'CNC per sheet',
  markUp: 'MARKUP',
  oneMmCostPerLF: '1mm $$/LF',
  twoMmCostPerLF: '2mm $$/LF',
  threeMmCostPerLF: '3mm $$/LF',
  scribeShoeCostPerPiece: 'Scribe/Shoe $$/Piece',
};

export const numericFields = [
  'Index',
  'MatID',
  'SqFt/ Sheet',
  'Waste',
  'Cost $$/SqFt',
  'Filler $$/SqFt',
  'Panel $$/SqFT',
  'Hinge $$/Bore',
  'Add per Order',
  '3" Crown $$/Piece',
  '4" Crown $$/Piece',
  '3" Base $$/Piece',
  '4" Base $$/Piece',
  'CNC per sheet',
  'MARKUP',
  '1mm $$/LF',
  '2mm $$/LF',
  'Scribe/Shoe $$/Piece',
];

export const cabinetDetails = [
  {
    title: 'About Cabinet',
    fields: [
      { label: 'Index', id: 'index', type: 'number' },
      { label: 'Code', id: 'code', type: 'string' },
      { label: 'Short Name', id: 'shortName', type: 'string' },
      { label: 'Long Name', id: 'longName', type: 'string' },
      { label: 'Description', id: 'description', type: 'string' },
    ],
  },
  {
    title: 'Default Values',
    fields: [
      { label: 'Width', id: 'width', type: 'number' },
      { label: 'Min Width', id: 'minWidth', type: 'number' },
      { label: 'Max Width', id: 'maxWidth', type: 'number' },
      { label: 'Height', id: 'height', type: 'number' },
      { label: 'Min Height', id: 'minHeight', type: 'number' },
      { label: 'Max Height', id: 'maxHeight', type: 'number' },
      { label: 'Depth', id: 'depth', type: 'number' },
      { label: 'Min Depth', id: 'minDepth', type: 'number' },
      { label: 'Max Depth', id: 'maxDepth', type: 'number' },
    ],
  },
  {
    title: 'Case',
    fields: [
      { label: 'Case Shelves', id: 'caseShelves', type: 'number' },
      { label: 'Case Partitions', id: 'casePartitions', type: 'number' },
      { label: 'Case Inches', id: 'caseInches', type: 'string' },
    ],
  },
  {
    title: 'Door',
    fields: [
      { label: 'Width Formula', id: 'doorWidth', type: 'string' },
      { label: 'Height Formula', id: 'doorHeight', type: 'string' },
      {
        label: 'Vertical Banding',
        id: 'verticalBanding',
        type: 'number',
      },
      {
        label: 'Horizontal Banding',
        id: 'horizontalBanding',
        type: 'number',
      },
    ],
  },
  {
    title: 'Hardware',
    fields: [
      { label: 'Accessories Cost', id: 'accessoriesCost', type: 'number' },
      { label: 'Hinge Height Formula', id: 'hingeHeight' },
    ],
  },
  {
    title: 'Drawer Box',
    fields: [
      { label: 'D. Box 1 Width ', id: 'db1Width' },
      { label: 'D. Box 1 Height ', id: 'db1Height' },
      { label: 'D. Box 2 Width ', id: 'db2Width' },
      { label: 'D. Box 2 Height ', id: 'db2Height' },
      { label: 'D. Box 3 Width ', id: 'db3Width' },
      { label: 'D. Box 3 Height ', id: 'db3Height' },
      { label: 'D. Box 4 Width ', id: 'db4Width' },
      { label: 'D. Box 4 Height ', id: 'db4Height' },
      { label: 'D. Box 5 Width ', id: 'db5Width' },
      { label: 'D. Box 5 Height ', id: 'db5Height' },
      { label: 'D. Box 6 Width ', id: 'db6Width' },
      { label: 'D. Box 6 Height ', id: 'db6Height' },
    ],
  },

  {
    title: 'Additional',
    fields: [
      {
        label: 'Additional Assembly Minutes',
        id: 'additionalAssemblyMin',
        type: 'number',
      },
    ],
  },
  {
    title: 'Filters',
    fields: [
      {
        label: 'Show Toekick',
        id: 'toekick',
        type: 'select',
        options: ['Yes', 'No'],
      },
      {
        label: 'Show Reveals',
        id: 'reveals',
        type: 'select',
        options: ['Yes', 'No'],
      },
      {
        label: 'Show Adjustment Shelves',
        id: 'adjShelves',
        type: 'select',
        options: ['Yes', 'No'],
      },
    ],
  },
  {
    title: 'Filters Default Values',
    fields: [
      { label: 'No. of Doors', id: 'noOfDoors', type: 'number' },
      { label: 'No. of Drawers', id: 'noOfDrawer', type: 'number' },
      {
        label: 'No. of Adjustment Shelves',
        id: 'noOfAdjShelves',
        type: 'number',
      },
      { label: 'Toekick Height', id: 'toekickHeight', type: 'number' },
      { label: 'Toekick Depth', id: 'toekickDepth', type: 'number' },
      { label: 'Max Toekick Depth', id: 'maxToekickDepth', type: 'number' },
      { label: 'Min Toekick Depth', id: 'minToekickDepth', type: 'number' },
      { label: 'Max Toekick Height', id: 'maxToekickHeight', type: 'number' },
      { label: 'Min Toekick Height', id: 'minToekickHeight', type: 'number' },
    ],
  },
  {
    title: 'Additional Parameter 1',
    fields: [
      { label: 'Name', id: 'nameP1', type: 'string' },
      { label: 'Desc', id: 'descP1', type: 'string' },
      { label: 'Type', id: 'typeP1', type: 'string' },
      { label: 'Min', id: 'minValueP1', type: 'number' },
      { label: 'Max', id: 'maxValueP1', type: 'number' },
      { label: 'Formula R', id: 'FormulaP1', type: 'string' },
    ],
  },
  {
    title: 'Additional Parameter 2',
    fields: [
      { label: 'Name', id: 'nameP2', type: 'string' },
      { label: 'Desc', id: 'descP2', type: 'string' },
      { label: 'Type', id: 'typeP2', type: 'string' },
      { label: 'Min', id: 'minValueP2', type: 'number' },
      { label: 'Max', id: 'maxValueP2', type: 'number' },
      { label: 'Formula S', id: 'FormulaP2', type: 'string' },
    ],
  },
  {
    title: 'Additional Parameter 3',
    fields: [
      { label: 'Name', id: 'nameP3', type: 'string' },
      { label: 'Desc', id: 'descP3', type: 'string' },
      { label: 'Type', id: 'typeP3', type: 'string ' },
      { label: 'Min', id: 'minValueP3', type: 'number' },
      { label: 'Max', id: 'maxValueP3', type: 'number' },
      { label: 'Formula T', id: 'FormulaP3', type: 'string ' },
    ],
  },
  {
    title: 'Additional Parameter 4',
    fields: [
      { label: 'Name', id: 'nameP4', type: 'string' },
      { label: 'Desc', id: 'descP4', type: 'string' },
      { label: 'Type', id: 'typeP4', type: 'string' },
      { label: 'Min', id: 'minValueP4', type: 'number' },
      { label: 'Max', id: 'maxValueP4', type: 'number' },
      { label: 'Formula U', id: 'FormulaP4', type: 'string' },
    ],
  },
  {
    title: 'Additional Parameter 5',
    fields: [
      { label: 'Name', id: 'nameP5', type: 'string ' },
      { label: 'Desc', id: 'descP5', type: 'string' },
      { label: 'Type', id: 'typeP5', type: 'string' },
      { label: 'Min', id: 'minValueP5', type: 'number' },
      { label: 'Max', id: 'maxValueP5', type: 'number' },
      { label: 'Formula V', id: 'FormulaP5', type: 'string' },
    ],
  },
  {
    title: 'Additional Parameter 6',
    fields: [
      { label: 'Name', id: 'nameP6', type: 'string ' },
      { label: 'Desc', id: 'descP6', type: 'string ' },
      { label: 'Type', id: 'typeP6', type: 'string' },
      { label: 'Min', id: 'minValueP6', type: 'number' },
      { label: 'Max', id: 'maxValueP6', type: 'number' },
      { label: 'Formula W', id: 'FormulaP6', type: 'string ' },
    ],
  },
];

export const FILTERS = [
  {
    title: 'Categories',
    children: [
      { id: 'Melamine/TFL Doors', label: 'Melamine/TFL Doors' },
      { id: 'Misc Doors', label: 'Misc Doors' },
      { id: 'Thermofoil/RTF Doors', label: 'Thermofoil/RTF Doors' },
      {
        id: 'Painted or Stained Wood Doors - Standard Colors',
        label: 'Painted or Stained Wood Doors - Standard Colors',
      },

      {
        id: 'Painted or Stained Wood Doors - Custom Colors',
        label: 'Painted or Stained Wood Doors - Custom Colors',
      },
    ],
  },
  {
    title: 'Styles',
    children: [
      { id: 'slab', label: 'Slab' },
      { id: 'raised_panel', label: 'Raised Panel' },
      { id: 'shaker', label: 'Shaker' },
      { id: 'mitered', label: 'Mitered' },
      { id: 'traditional', label: 'Traditional' },
      { id: 'contemporary', label: 'Contemporary' },
    ],
  },
  {
    title: 'Colors and Textures',
    children: [
      { id: 'white', label: 'White' },
      { id: 'grey', label: 'Grey' },
      { id: 'black', label: 'Black' },
      { id: 'dark', label: 'Dark' },
      { id: 'light', label: 'Light' },
      { id: 'woodGrain', label: 'Wood Grain' },
      { id: 'stone', label: 'Stone' },
    ],
  },
  {
    title: 'Woods',
    children: [
      { id: 'walnut', label: 'Walnut' },
      { id: 'maple', label: 'Maple' },
      { id: 'oak', label: 'Oak' },
      { id: 'cherry', label: 'Cherry' },
      { id: 'birch', label: 'Birch' },
    ],
  },
  {
    title: 'Brands',
    children: [
      { id: 'tafisa', label: 'Tafisa' },
      { id: 'egger', label: 'Egger' },
      { id: 'prism', label: 'Prism' },
      { id: 'brushy_creek', label: 'Brushy Creek' },
      { id: 'cal_doors', label: 'CalDoors' },
      { id: 'northern_contours', label: 'Northern Contours' },
    ],
  },
];
